import intlTelInput from 'intl-tel-input';

(() => {
  const mobilePhoneInputs = document.querySelectorAll('.mobile-phone-field__country-selector');

  if (mobilePhoneInputs) {
    mobilePhoneInputs.forEach((mobilePhoneInput) => {
      const iti = intlTelInput(mobilePhoneInput, {
        loadUtilsOnInit: 'https://cdn.jsdelivr.net/npm/intl-tel-input@24.6.0/build/js/utils.js',
        autoPlaceholder: 'aggressive',
        separateDialCode: true,
        initialCountry: 'us',
        strictMode: true,
      });

      const form = mobilePhoneInput.closest('form');
      form.addEventListener('submit', function (e) {
        e.preventDefault();

        // convert the formatted number to E.164 format
        // example: +1 123-456-7890 => +112345678
        if (iti.isValidNumber()) {
          const fullNumber = iti.getNumber();
          mobilePhoneInput.value = fullNumber;
          this.submit();
        }
      });
    });
  }
})();
